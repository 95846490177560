import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'antd/dist/antd.css'; 
//import 'antd/dist/result.css';

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'
import { BrowserRouter as Router } from 'react-router-dom'

import LoginLayout from './LoginLayout'
import Login from './Login';


class App extends Component {

  componentDidMount(){

  }

  render() {
    return (
        <LoginLayout>
          <Login />
        </LoginLayout>
    );
  }
}


export default App;
